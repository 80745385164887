(function($) {
    'use strict';



//Smooth scroll  

    // Select all links with hashes
    $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function(event) {
        // On-page links
        if (
            location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&  location.hostname == this.hostname
        ) {
            // Figure out element to scroll to
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            // Does a scroll target exist?
            if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: target.offset().top - 185
                }, 1000, function() {
                    // Callback after animation
                });
            }
        }
    });

    /**
     * Add a 'Back' link to sub menus
     */
    $('.sub-menu').each(function() {
        $(this).prepend('<li class="menu__item menu__item--back">Back</li>');
    });

    $('.menu__item--back').on('click', function(e) {
        e.preventDefault();
        $(this).closest('.is-active').removeClass('is-active');
        $(this).parents('nav').removeClass('is-active');
        $('.sub-menu > .menu__item').hide();
        $('body').removeClass('sub-nav-is-active');
    });


    /**
     * Register click events on sub navigation toggles
     */

    $('.sub-menu > .menu__item').hide();
    $('.menu__item--has-children > a').on('click', function(e) {

        e.preventDefault();
        $('.c-nav-primary').addClass('is-active');

        var parent = $(this).parent(),
            menu = $(this).closest('ul');

        menu.find('.menu__item').removeClass('is-active');

        parent.addClass('is-active');
        $('body').addClass('sub-nav-is-active');


        $('.sub-menu > .menu__item').show();

        $(".menu__item:not(.is-active) > .sub-menu > .menu__item").hide();
        
    });

    // Wrap every letter in a span
    $('.c-hero__subheading').each(function(){
        $(this).html($(this).text().replace(/([^\x80-\xFF]|\w)/g, "<span class='c-hero__heading-letter'>$&</span>"));
    });
    
    anime.timeline()
    .add({
        targets: '.c-hero__heading-letter',
        translateX: [40,0],
        translateZ: 0,
        opacity: [0,1],
        easing: "easeOutExpo",
        duration: 1200,
        autoplay: false,
        delay: function(el, i) {
          return 1500 + 40 * i;
        }
    });

     /**
     * Detect lang attribute and then change input placeholder text
     */

    if ($('html').is(':lang(fr)')) {
        $('.c-booking-bar--arrive .c-booking-bar__date').attr('placeholder', 'Date d’arrivée');
        $('.c-booking-bar--depart .c-booking-bar__date').attr('placeholder', 'Date de départ');
    }

    /**
     * Targets hero scroller and inserts new hyperlink attribute to allow fullpage to scroll to the next section. 
     */

    $('.c-layout-builder-fullpage .c-hero__scroller').attr('href', '#fullpage-anchor');

    /** 
     * Add class to full page widget slide when that slide is in the viewport 
     */
     /**
     * Add visible classes to widgets
     */
    // var rowWaypoints = $('.c-layout-builder-fullpage__section').waypoint({
    //     handler: function(direction) {
    //         $(this.element).addClass('slide-is-visible');
    //     },
    //     offset: '80%'
    // });

    // * Amends CTA specific offset for waypoint
    $(document).on('cnInitWidgetWaypoints', function (event, data) {
        var element = data && data.element || document;
        var overrideEl = '.c-layout-builder-fullpage__section'

        $(element).find(overrideEl).waypoint({
            handler: function(direction) {
                $(this.element).addClass('slide-is-visible');
            },
            offset: '80%'
        });
    });


})(jQuery);

